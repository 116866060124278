export default {
  lang: "Ўзбекча",
  code: "UZ",
  interface: {
    search: "Излаш",
    logout: "Чиқиш",
    settings: "Созламалар",
    detail: "Батафсил",
    choose: "Танлаш",
    back: "Орқага",
    download: "юклаш",
    confirm_action: "Амални тасдиқланг",
    remove_attention_message: "Сиз ҳақиқатан ҳам ушбу объект ва унга тегишли таркибни учириб ташламокчимисиз? Мазкур амални бекор қилиб бўлмайди.",
    cancel: "Бекор қилиш",
    send: "Юбориш",
    publish: "Чоп этиш",
    submit: "Қабул қилиш",
    save: "Сақлаш",
    remove_object: "Объектни ўчириш",
    type_note: "Изоҳни киритинг",
    ok: "Ок",
  },
  routes: {
    home: "Бош саҳифа",
    archive: "Индекслар",
    rating_detail: "Индекс",
    sub_rating_detail: "Муддат",
    requestion_detail: "Ариза",
    indicator_detail: "Мезон",
    events: "Тадбирлар тақвими",
    manageEvents: "Тадбирларни бошқариш",
  },
  user: {
    role: {
      coordinator: "Координатор",
      expert: "Эксперт",
      applicant: "Аризачи",
      security: "Хавфсизлик ходими",
    }
  },
  home: {
    welcome: "Хуш келибсиз",
    docs: {
      title: "Қўлланма билан танишинг",
      desc: "Ўз самарадорлигингизни оширинг",
      action: "Қўлланмани очиш"
    },
    rating: {
      title: "Менинг индексларим",
      action: "Ўтиш"
    },
    help: {
      title: "Савол борми?",
      action: "Қўллаб-қувватлаш хизмати"
    },
    major_tasks: "Муҳим вазифалар",
    notifications: "Сўнгги тадбирлар",
  },
  events: {
    manage: "Тадбирларни бошқариш",
    create: "Тадбирларни яратиш",
    created_at: "яратилди",
    info_type: "тури",
    cancel: "Тадбирнини бекор қилиш",
    cancel_attention: "Сиз ҳақиқатан ҳам ушбу тадбирни бекор қилмоқчимисиз? Мазкур ҳаракатни бекор қилиб бўлмайди.",
    type: {
      periodic: "Такрорий тадбир",
      exact_date: "Бир марталик тадбир"
    },
    status: {
      draft: "Қоралама",
      published: "Чоп этилди",
      cancelled: "Бекор қилинди",
    },
    period: {
      week: "Ҳар ҳафта",
      month: "Ҳар ой",
      quarter: "Ҳар чорак",
      "half-year": "Ҳар ярим йиллик",
      year: "Ҳар йил",
    }
  },
  archive: {
    create: "Янги индекс"
  },
  proof: {
    create: "Тасдиқни қўшиш",
    proof: "Тасдиқлар",
  },
  indicator: {
    proofs: "тасдиқлар",
    criterions: "Жавоблар",
    criterions_and_proofs: "Жавоб ва тасдиқлар",
    file: "файл",
    link: "ҳавола",
    criterions_count: "баҳо",
    max_score: "максимал балл",
    org_score: "ўзини ўзи баҳолаш",
    expert_score: "эксперт баҳоси",
    coordinator_score: "координатор баҳоси",
    periodicity: "даврийлик",
    calculation_method: "ҳисоблаш усули",
  },
  criterion: {
    create: "Жавоб қўшиш",
    score: "балл",
    organization_choose: "Ташкилотни танлаш",
    expert_choose: "Экспертни танлаш",
    coordinator_choose: "Координаторни танлаш",
    reduce_score_modal: "Баҳони ўзгариш сабабини кўрсатинг",
  },
  history: {
    is_rejected: "Қайтарилган",
    author: "муаллиф"
  },
  rating: {
    requestions: "аризалар",
    created_at: "яратилган",
    published: "чоп этилган",
    open: "аризаларни қабул қилиш",
    evalution: "экспертлар баҳолаши",
    result_publish: "натижаларни чоп этиш",
    clone: "Клон яратиш",
    is_main: '',
    tabs: {
      subindex: "Индикаторлар",
      requestions: "Аризалар",
      history: "Хронология",
      report: "Йиғма ҳисобот",
      members: "Ташкилотлар",
      subrating: "Мониторинг",
    }
  },
  subindex: {
    indicators_count: "индикаторлар",
    max_score: "максимал балл",
    reduce_score: "пасайтириш балли",
    current_score: "жорий балл",
    final_score: "якуний балл",
    create: "Индикаторни қўшиш",
    org_score: "ўзини ўзи баҳолаш",
    expert_score: "эксперт баҳоси",
    coordinator_score: "координатор баҳоси",
    progress_by_applicant: "ташкилот прогресси ",
    progress_by_expert: "экспертлар прогресси",
    reduce_score_modal: "Баҳони пасайтириш",
    annual_score: "Йиллик балл",
    preliminary_score: "Дастлабки йиллик балл",
    reduced_annual_score: "пасайтириш ҳисоби билан йиллик балл",
  },
  report: {
    organization: "Ташкилот",
    total_score: "Жами баллар",
    scores: "баллар"
  },
  requestion: {
    claim: "Шикоят",
    created_at: "яратилган",
    published: "чоп этилган",
    total_score: "жами балл",
    progress_by_applicant: "ташкилот прогресси",
    progress_by_expert: "эксперт прогресси ",
    total_score: "жами балл",
    create_claim: "Шикоят яратиш",
    status: "Ариза статуси",
    reject_scores: "Экспертга қайтариш",
    tabs: {
      subindex: "Индикаторлар",
      history: "Хронология",
    },
    statuses: {
      all: "Ҳаммаси",
      "2": "Яратилган",
      "3": "Ариза жойлаштирилган",
      "4": "Модерация",
      "5": "Аризани баҳолаш",
      "6": "Баҳолаш модерацияси",
      "7": "Ариза қабул қилинган",
      "8": "Юборилмаган",
      "9": "Ариза баҳоси қабул қилинган",
      "10": "Натижаларни сарҳисоб қилиш",
      "11": "Ёпилган",
      "13": "Бекор қилинган",
    }
  },
  settings: {
    locale: "Локализация",
    locale_desc: "Интерфейс тилини ўзгартириш",
    interface: "Интерфейс",
    interface_desc: "Интерфейснинг умумий созламалари",
    password: "Паролни ўзгартириш",
    password_desc: "Паролни ўзгартириш",
    font_size: "Шрифт ўлчами"
  },
  forms: {
    indicator: "Мезон",
    subindex: "Индикатор",
    score: "Баҳо",
    order_number: "Тартиб №",
    claim: "Шикоят",
    file: "Файл",
    link: "Ҳавола",
    desc_placeholder: "Тавсифни киритинг",
    name_placeholder: "Номини киритинг",
    name: "Номи",
    desc: "Тавсиф",
    event_type: "Тадбир тури",
    date: "Сана",
    days_of_warning: "Тадбиргача бўлган кунлар",
    repeat: "Қайта такрорлаш",
    notifications: "Хабарномалар",
    daily_notifications: "Кунлик хабарномалар",
    calendar_color: "Календарь ранги",
    text: "Матн",
    links: "Ҳавола",
    files: "Файллар",
    confirm_types: "Тасдиқлаш тури",
    organizations: "Ташкилотлар",
    members: "Иштирокчилар",
    year: "Ҳисобот даври",
    index_type: "Индекс тури",
    open_date: "Аризалар қабули бошланиши",
    close_date: "Аризалар қабули якунланиши",
    evalution_open_date: "Экспертлар баҳолашининг бошланиши",
    evalution_close_date: "Экспертлар баҳолашининг якунланиши",
    result_publish_date: "Натижаларни эълон қилиш санаси",
    reduce_score: "Пасайтириш баҳоси",
    short_name: "Аббревиатура",
    expert_organization: "Эксперт ташкилоти",
    can_reduced: "Баҳони пасайтиришга рухсат бериш",
    max_reduce_score: "Максимал пасайтириш балли",
    index_periodicity: "даврийлик",
    index_calculation_method: "Ҳисоблаш усули",
  },
  validations: {
    required: "Ушбу майдонча тўлдириш учун мажбурийдир.",
    str_min: "Майдончада {min} кам бўлмаган белгилар бўлиши керак.",
    positive_number: "Рақам нольдан баланд ёки тенг бўлиши керак.",
    num_min: "Рақам {min} дан кам бўлмаслиги керак.",
    url: "Тўғри форматдаги ҳаволани кўрсатинг.",
    file: "Юклаш учун файл танланмаган.",
    at_least_one: "Рўйхатдан бирини танланг",
  },
  claim: {
    title: "Шикоят",
    letter: "шикоят хати",
    created_at: "яратилган",
    expert_answer: "{expert} жавоби",
    download_attachments: "иловани юклаш",
    attachments: "илова",
    indicators: "Мезонлар бўйича шикоят",
    indicator_create: "Мезон балли бўйича шикоят қўшиш",
    subindexes: "Пасайтириш балли бўйича шикоят",
    subindexes_create: "Пасайтириш балли бўйича шикоят қўшиш",
    answer: "Жавоб бериш",
    remove_answer: "Жавобларни ўчириш",
    org_score: "ўзини ўзи баҳолаш",
    expert_score: "эксперт баҳоси",
    reduce_score: "пасайтириш бали",
    indicator_modal: "Мезон балли учун шикоят",
    subindex_modal: "Пасайтириш балли бўйича шикоят",
    answer_modal: "Шикоятга жавоб",
    answer_modal_desc: "Изоҳни тўлдиринг ва жавоб хатини бириктиринг.",
  }
};
